import React from "react"
import "./index.scss"
import InlineColumn from "../InlineColumn"

function layoutClasses(layout, singleColumnOnMobile, verticalPadding) {
  var layoutClasses = []

  // set mobile collape options
  if (singleColumnOnMobile) {
    layoutClasses.push('section-layout--collapse-mobile')
  }

  // set column layout
  layoutClasses.push('section-layout--'.concat(layout))

  // set vertical padding
  layoutClasses.push('section-layout--vertical-padding-'.concat(verticalPadding))

  return layoutClasses.join(' ')
}

export default function SectionLayout({layout, singleColumnOnMobile, verticalPadding, columns }) {

  return (
    <div className={['section-layout page-section__content', layoutClasses(layout, singleColumnOnMobile, verticalPadding)].join(' ')}>
      { columns.map(column => { 
        return (
          <InlineColumn 
            key={column.id} 
            columnData={column} />
        )})
      }
    </div>
  )
}
