import React from "react"
import { Link } from "gatsby"

function getUrlPath(record) {
  switch (record.__typename) {
    case 'DatoCmsFocusArea':
      return `/our-work/${record.slug}`
    case 'DatoCmsStrategicImperative':
      return `/our-work/${record.slug}`
    case 'DatoCmsProject':
      return `/our-work/projects/${record.slug}`
    case 'DatoCmsPage':
      return `/${record.slug}`
    case 'DatoCmsResource':
      return `/resources/${record.slug}`
    case 'DatoCmsUpdate':
      return `/updates/${record.slug}`
    case 'DatoCmsJob':
      return `/jobs/${record.slug}`
    case 'DatoCmsStaff':
      return `/about/team/${record.slug}`
    default:
      return null;
  }
}

export default function InternalLink({record, classes, children, openInNewTab=false}) {
  if (openInNewTab) {
    return (
      <a href={getUrlPath(record)} target="_blank" rel="noopener noreferrer" className={classes}>{children}</a>
    )
  } else {
    return (
      <Link to={getUrlPath(record)} className={classes}>{children}</Link>
    )
  }
}
