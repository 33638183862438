import React from "react"
import InternalLink from '../InternalLink'
import InlineImage from '../InlineImage'
import { StructuredText } from 'react-datocms'
import "./index.scss"

function PhotoCardComponentWrapper({cardData, classes, children}) {
  switch (cardData.linkType) {
    case "internal":
      return (
        <InternalLink
          record={cardData.internalLink}
          classes={classes}
        >{children}</InternalLink>
      )
    case "external":
      return (
        <a href={cardData.externalLink} target="_blank" rel="noopener noreferrer" className={classes}>{children}</a>
      )
    case "file":
      return (
        <a href={cardData.fileLink.url} target="_blank" rel="noopener noreferrer" className={classes}>{children}</a>
      )
    case "none":
      return (
        <div className={classes}>{children}</div>
      )
  }
}

function styleClasses(cardData) {
  
  var styleClasses = []

  styleClasses.push('photo-card-overlay--theme-'.concat(cardData.backgroundColor))
  styleClasses.push('photo-card-overlay--layout-'.concat(cardData.layout))

  return styleClasses.join(' ')
}

export default function InlinePhotoCardOverlay({cardData}) {
  return (
    <PhotoCardComponentWrapper
      cardData={cardData}
      classes={`photo-card-overlay page-section__content ${styleClasses(cardData)}`}
    >

      { cardData.image && (
        <InlineImage imageData={cardData.image} classes="photo-card-overlay__image" />
      )}

      <div className="photo-card-overlay__card">

        { cardData.cardText && (
          <StructuredText 
            data={cardData.cardText}
            renderLinkToRecord={({ record, children }) => {
              return (<InternalLink
                record={record}
                classes=""
                children={children} 
              />)
            }}
          />
        )}

        { cardData.cardMoreText && (
          <p className="photo-card-overlay__more-text">{cardData.cardMoreText}</p>
        )}

      </div>

    </PhotoCardComponentWrapper>
  )
}
