import React from "react"
import PageSection from "../PageSection"
import SectionLayout from "../SectionLayout"
import InlinePhotoCardOverlay from "../InlinePhotoCardOverlay"
import ChapterCover from "../ChapterCover"
import MapWithClickableInfo from "../MapWithClickableInfo"
import ModalsSlider from "../ModalsSlider"

export default function pageSectionWithLayouts(sectionData) {

  const layoutComponents = sectionData.sectionData.layouts.map(layout => {
    switch (layout.__typename) {
      case "DatoCmsSectionLayout":
        return (
          <SectionLayout
            key = {layout.id}
            layout={layout.layout}
            singleColumnOnMobile={layout.singleColumnOnMobile}
            verticalPadding={layout.verticalPadding}
            columns={layout.columns}
          />
        )
      case "DatoCmsInlinePhotoCardOverlay":
        return (
          <InlinePhotoCardOverlay
            key={layout.id}
            cardData={layout}
          />
        )
      case "DatoCmsChapterCover":
        return (
          <ChapterCover
            key={layout.id}
            chapterData={layout}
          />
        )
      case "DatoCmsMapWithClickableInfo":
        return (
          <MapWithClickableInfo
            key={layout.id}
            mapData={layout}
          />
        )
        // return null;
      case "DatoCmsModalsSlider":
        return (
          <ModalsSlider
            key={layout.id}
            sliderData={layout}
          />
        )
    }
  })

  return (
    <PageSection
      key={sectionData.sectionData.id}
      replaceGridElement={true}
      colorTheme={sectionData.sectionData.backgroundColor}
      sectionData={sectionData}
    >
      {layoutComponents}
    </PageSection>
  )
}
