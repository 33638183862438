import React , { useState } from 'react'
import ScrollyWrapper from '../ScrollyWrapper'
import Element from './Element';
import '../index.scss'


/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~
*
* This file contains: 
* - A wrapper element where all the logic for the intersectionObserver and progress function is handled
* - In the ScrollyWrapper component, we also have the steps content set in DatoCSM
* - An Element Component which is the object that will remain "sticky" while the user scroll  
*
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */


export default function ScrollyElement ({component}) {
    const { componentName, steps, data, title, description } = component;
    // get the progress data for custom interaction from the scrollyWrapper and pass it to the interactive Element
    const [progress, setProgress] = useState({
        overallPct: 0,
        currentStep: 0,
        currentStepPct: 0,
      })

    return (
        <ScrollyWrapper onProgressHandler={setProgress} steps={steps}>
            <Element title={title} description={description} progress={progress} data={data} component_name={componentName} />
        </ScrollyWrapper>
    )
}

