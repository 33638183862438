import React, { useRef, useEffect, useState } from "react"
import {
  select,
  line,
  scaleLinear,
  scaleTime,
  axisBottom,
  axisLeft,
  csv,
  timeParse,
  format,
  max,
  extent,
} from 'd3'
import useResizeObserver from '../useResizeObserver'
import '../../index.scss'

const dateParse = timeParse('%B %Y')
const dateParseShort = timeParse('%b %Y')
const percFormat = format('.0%')

const isBrowser = typeof window !== 'undefined'
let isMobile = true;
if (isBrowser) {
  isMobile =  window.innerWidth > 771;
}

const margin = {
  top: 10,
  right: isMobile ? 80 : 30,
  bottom: isMobile ? 100 : 50,
  left: isMobile ? 50 : 30,
}

const labelPadding = 6

const colors = {
  nycFare: '#655DC6',
  chicagoFare: '#18988B',
  inflation: '#FD495C',
}

const legends = {
  nycFare: 'NYC Fares',
  chicagoFare: 'Chicago Fares',
  inflation: 'Inflation Rate',
}

const intialOpacity = {
  nycFare: 0,
  chicagoFare: 0,
  inflation: 1,
}

const chartConfig = {
  0: [
    {
      el: '.chicagoFare',
      opacity: 0,
    },
    {
      el: '.nycFare',
      opacity: 0,
    },
    {
      el: '.inflation',
      opacity: 1,
    },
  ],
  1: [
    {
      el: '.chicagoFare',
      opacity: 1,
    },
    {
      el: '.nycFare',
      opacity: 1,
    },
    {
      el: '.inflation',
      opacity: 1,
    },
  ],
}

const isNumeric = str => {
  if (typeof str != 'string') return false // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ) // ...and ensure strings of whitespace fail
}

// Line component
function Line(props) {
  const {
    data,
    className,
    color,
    width,
    x,
    y,
    svg,
    opacity,
    legend,
    value,
    labelOffsetY,
    labelKeyPosX,
    labelKeyPosY,
  } = props

  const sortedData = data.sort((a, b) => a.date - b.date)
  const first = sortedData[0]
  const last = sortedData[sortedData.length - 1]

  // Create the line generator.
  let lineShape = line()
    .x(d => x(d.date))
    .y(d => y(d[value]))

  svg
    .select(`g.scrolly__chart__label.scrolly__chart__label--min.${className}`)
    .attr(
      'transform',
      `translate(${x(first.date) - 30},${y(first[value]) - labelOffsetY})`
    )
    .select('text')
    .attr('dy', '15px')
    .text(percFormat(first[value]))

  svg
    .select(`g.scrolly__chart__label.scrolly__chart__label--max.${className}`)
    .attr('transform', `translate(${x(last.date)},${y(last[value])})`)
    .select('text')
    .attr('dy', '-10px')
    .text(percFormat(last[value]))

  svg
    .select(`g.scrolly__chart__label.scrolly__chart__label--key.${className}`)
    .attr(
      'transform',
      `translate(${x(dateParse(labelKeyPosX))}, ${y(labelKeyPosY)})`
    )
    .select('text')
    .text(d => legend)

  svg
    .selectAll(`g.scrolly__chart__label.${className} rect`)
    .datum(function () {
      return this.nextSibling.getBBox()
    })
    .attr('x', d => d.x - labelPadding)
    .attr('y', d => d.y - labelPadding)
    .attr('width', d => d.width + 2 * labelPadding)
    .attr('height', d => d.height + 2 * labelPadding)
    .attr('rx', 2)

  // Draw the line.
  svg
    .select(`path.scrolly__chart__line.${className}`)
    .attr('d', lineShape(sortedData))

  return (
    <g className={`${className}`} opacity={opacity}>
      <path
        className={`scrolly__chart__line ${className}`}
        strokeWidth={width}
        stroke={color}
        fill='none'
      />
      <g
        className={`scrolly__chart__label scrolly__chart__label--min ${className}`}
      >
        <rect></rect>
        <text fill={color}></text>
      </g>
      <g
        className={`scrolly__chart__label scrolly__chart__label--max ${className}`}
      >
        <rect></rect>
        <text fill={color}></text>
      </g>
      <g
        className={`scrolly__chart__label scrolly__chart__label--key ${className}`}
      >
        <rect fill={color}></rect>
        <text></text>
      </g>
    </g>
  )
}

export default function DefaultChart(props) {
  const { overallPct, currentStep, currentStepPct, data, title, description } =
    props
  const [importedData, setData] = useState(null)
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const svgRef = useRef(null)
  const svgContainerRef = useRef(null)
  const { width } = useResizeObserver(svgContainerRef)

  // Set the svg height to half its width, but don't let it go lower than 320.
  const height = Math.max(width / 2, 600)
  const xRef = useRef(
    scaleTime()
      .range([margin.left, width - margin.right])
      .nice()
  )
  // Create the y-scale.
  const yRef = useRef(
    scaleLinear()
      .range([height - margin.bottom, margin.top])
      .nice()
  )

  const drawAxes = data => {
    const arr = data.flat()
    const svg = select(svgRef.current)
    const x = xRef.current
    const y = yRef.current

    const sortedData = arr.sort((a, b) => a.date - b.date)

    let values = []

    sortedData.forEach(d => {
      values.push(d.chicagoFare, d.nycFare, d.inflation)
    })

    x.range([margin.left, width - margin.right])
    y.range([height - margin.bottom, margin.top])
    x.domain([dateParse('October 2018'), max(sortedData, d => d.date)])
    y.domain(extent(values))

    svg
      .select('g.scrolly__chart__axis--x')
      .attr('transform', d => `translate(0, ${height - margin.bottom})`)
      .call(axisBottom(x).ticks(10).tickSizeOuter(0))
      .selectAll('text')
      .attr('y', 0)
      .attr('x', 9)
      .attr('dy', '.35em')
      .attr('transform', 'rotate(90)')
      .style('text-anchor', 'start')
      .filter(function () {
        return isNumeric(select(this).text())
      })
      .attr('font-weight', 'bold')

    svg
      .select('g.scrolly__chart__axis--y')
      .attr('transform', d => `translate(${margin.left})`, 0)
      .call(
        axisLeft(y)
          .ticks(height / 50)
          .tickFormat(d => percFormat(d))
      )
      .call(g => g.select('.domain').remove())
      .call(g =>
        g
          .selectAll('.tick line')
          .clone()
          .attr('stroke-opacity', 0.1)
          .attr('x2', width - margin.right - margin.left)
      )
  }

  const updateChart = step => {
    const changes = chartConfig[step]
    changes.forEach(change => {
      let el = change.el
      let opacity = change.opacity
      select(svgRef.current).select(`${el}`).attr('opacity', opacity)
    })
  }

  useEffect(() => {
    const fetchData = data => {
      let promises = []
      if (Array.isArray(data)) {
        promises = data.map(dataset =>
          csv(`https://www.datocms-assets.com/${dataset.path}`, d => {
            return {
              name: dataset.basename,
              date: dateParse(d.date) || dateParseShort(d.date),
              nycFare: parseFloat(d.nyc),
              chicagoFare: parseFloat(d.chicago),
              inflation: parseFloat(d.inflation),
            }
          })
        )
      }

      Promise.all(promises).then(data => {
        setData(data)
        setIsDataLoaded(true)
      })
    }
    fetchData(data)
  }, [])

  useEffect(() => {
    select(svgRef.current)
      .attr('viewBox', [0, 0, width, height])
      .attr('width', width)
      .attr('height', height)
  }, [width, height])

  useEffect(() => {
    if (importedData) {
      drawAxes(importedData)
    }
  }, [importedData])

  useEffect(() => {
    if (currentStepPct > 0.5) {
      updateChart(currentStep)
    }
  }, [currentStepPct])

  return (
    <div ref={svgContainerRef} className="scrolly__wrapper--chart">
      {title && <h4 className="scrolly__chart__title">{title}</h4>}
      <svg ref={svgRef} className="scrolly__chart">
        <g className="scrolly__chart__axis scrolly__chart__axis--x" />
        <g className="scrolly__chart__axis scrolly__chart__axis--y" />
        {importedData ? (
          importedData.map((d, i) => (
            <>
              <Line
                className={"nycFare"}
                key={i}
                data={d}
                value={"nycFare"}
                color={colors["nycFare"]}
                width={"2px"}
                isArea={false}
                y={yRef.current}
                opacity={intialOpacity["nycFare"]}
                x={xRef.current}
                svg={select(svgRef.current)}
                legend={legends["nycFare"]}
                labelOffsetY={0}
                labelKeyPosY={0.25}
                labelKeyPosX={"January 2020"}
              ></Line>

              <Line
                className={"chicagoFare"}
                key={i + 1}
                data={d}
                value={"chicagoFare"}
                color={colors["chicagoFare"]}
                width={"2px"}
                isArea={false}
                y={yRef.current}
                opacity={intialOpacity["chicagoFare"]}
                x={xRef.current}
                svg={select(svgRef.current)}
                legend={legends["chicagoFare"]}
                labelOffsetY={60}
                labelKeyPosY={0.8}
                labelKeyPosX={"January 2020"}
              ></Line>

              <Line
                className={"inflation"}
                key={i * 2}
                data={d}
                value={"inflation"}
                color={colors["inflation"]}
                width={"2px"}
                isArea={false}
                y={yRef.current}
                opacity={intialOpacity["inflation"]}
                x={xRef.current}
                svg={select(svgRef.current)}
                legend={legends["inflation"]}
                labelOffsetY={20}
                labelKeyPosY={0.14}
                labelKeyPosX={"October 2021"}
              ></Line>
            </>
          ))
        ) : (
          <p>Data is loading or missing</p>
        )}
      </svg>
      {description && (
        <p className="scrolly__chart__description">{description}</p>
      )}
    </div>
  )
}