import "./index.scss"
import React, { useRef, createRef, useEffect, useState } from "react"
import { CSSTransition } from 'react-transition-group';
import mapboxgl from "!mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import InlineColumn from "../InlineColumn"
import InlineVideoEmbed from "../InlineVideoEmbed"
import InlineImage from "../InlineImage"

mapboxgl.accessToken = "pk.eyJ1IjoibWFyLXNwYWRhIiwiYSI6IjNka2J2VjgifQ.7wxvbiprk6i3s4D8HXSBpg"

function layoutClasses(layout) {
  return 'map-section--layout-'.concat(layout);
}

export default function MapWithClickableInfo({mapData}) {

  function scaleMap(map) {
    map.fitBounds([
      [-125.0, 24.396308], // southwestern corner of the continental US
      [-66.934570, 49.384358] // northeastern corner of the continental US
    ]);
  }

  // 
  var selectedPointInitialValue = '';
  if (mapData.mapPoints.length > 0) {
    selectedPointInitialValue = mapData.mapPoints[0].id
  }

  // set map basics
  const mapContainer = useRef(null);
  const [selectedPoint, setSelectedPoint] = useState(selectedPointInitialValue);

  // TK - option to have something besides the first point visible

  // Create points on the map
  const mapPoints = mapData.mapPoints.map(point => {
    return {
      type: "Feature",
      properties: {
        id: point.id,
      },
      geometry: {
        type: "Point",
        coordinates: [
          point.location?.longitude,
          point.location?.latitude,
        ],
      },
    }
  })

  const pointCollection = {
    type: "FeatureCollection",
    features: mapPoints,
  }

  // build map
  useEffect(() => {    
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mar-spada/cl0kxm7om005k15o8sduo4l50",
      center: [-95.535, 37.451],
      zoom: 3
    });

    // disable map interaction
    map.boxZoom.disable()
    map.scrollZoom.disable()
    map.dragPan.disable()
    map.dragRotate.disable()
    map.keyboard.disable()
    map.doubleClickZoom.disable()
    map.touchZoomRotate.disable()

    // set map bounding box
    scaleMap(map);

    // add points to the map
    map.on("load", () => {
      map.addSource("points", {
        type: "geojson",
        data: pointCollection,
      })
      map.addLayer({
        id: "points",
        type: "circle",
        source: "points",
        paint: {
          "circle-color": "#FD495C",
          "circle-radius": 10,
          "circle-stroke-width": 4,
          "circle-stroke-color": "#fff",
        },
        layout: {
          "circle-sort-key": 1,
        },
      })
    });

    // set cursor options
    map.on("mouseenter", "points", () => {
      map.getCanvas().style.cursor = "pointer"
    });
    map.on("mouseleave", "points", () => {
      map.getCanvas().style.cursor = "default"
    });

    // click events
    map.on("click", "points", e => {
      // console.log(e.features[0])
      // map.setPaintProperty(e.features[0].layer.id, "circle-color", "#000000");
      // ^ changes all points, research https://docs.mapbox.com/mapbox-gl-js/example/hover-styles/
      const clicked = e.features[0].properties.id
      setSelectedPoint(clicked);
    })

    // resize map on window resize
    window.addEventListener('resize', () => { scaleMap(map) });

  });

  // build list of point info blocks
  const pointInfoBlocks = mapData.mapPoints.map(point => {
    const nodeRef = createRef()

    return (
      <CSSTransition nodeRef={nodeRef} in={point.id === selectedPoint} appear={true} timeout={300} classNames="map-point-info-" key={point.id}>

        <li ref={nodeRef} className="map-section__point-info map-point-info" key={point.id} id={point.navSlug}>
          
          {point.mainVisual && (
            point.mainVisual.map(visual => {
              switch (visual.__typename) {
                case "DatoCmsInlineVideoEmbed":
                  return <InlineVideoEmbed videoData={visual} key={visual.id} />
                case  "DatoCmsInlineImage":
                  return <InlineImage imageData={visual.image} key={visual.id} />
              }
            })
          )}

          { point.headline && (
            <h3 className="map-point-info__headline">{point.headline}</h3>
          )}

          { point.displayLocation && (
            <p className="map-point-info__display-location">{point.displayLocation}</p>
          )}

          { point.mainContent && ( 
            point.mainContent.map(column => 
              <InlineColumn 
                key={column.id} 
                columnData={column} />
            )
          )}
        </li>
      </CSSTransition>
    )
  })

  return (
    <div className={['page-section__content map-section', layoutClasses(mapData.layout)].join(' ')}>

      <div className="map-section__map-column">
        <div ref={mapContainer} className="map-section__map" />

      </div>

      <div className="map-section__info-column">

        <ul className="map-section__points-info-list">
          {mapData.mapPoints.length > 0 && (
            pointInfoBlocks
          )}
        </ul>

      </div>

    </div>
  )
}
