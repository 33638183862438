import React from "react"
import InternalLink from '../InternalLink'
import "./index.scss"

function StatWrapper({statData, classes, children}) {
  switch (statData.link) {
    case "internal":
      return (
        <InternalLink
          record={statData.internalLink}
          classes={classes}
        >{children}</InternalLink>
      )
    case "external":
      return (
        <a href={statData.externalLink} target="_blank" rel="noopener noreferrer" className={classes}>{children}</a>
      )
    case "none":
      return (
        <div className={classes}>
          {children}
        </div>
      )
  }
}

function styleClasses(statData) {
  return 'inline-statistic--theme-'.concat(statData.highlightColor);
}

export default function InlineStatistic({statData}) {

  return (
    <StatWrapper
      statData={statData}
      classes={`inline-statistic ${styleClasses(statData)}`}
    >

      { statData.preStatText && (
        <p className="inline-statistic__pre-text">{statData.preStatText}</p>
      )}

      { statData.mainStatText && (
        <h4 className="inline-statistic__main-text">{statData.mainStatText}</h4>
      )}

      { statData.postStatText && (
        <p className="inline-statistic__post-text">{statData.postStatText}</p>
      )}

    </StatWrapper>
  )
}
