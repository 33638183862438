import React, { useRef, useEffect, useState } from 'react'
import {
  select,
  line,
  scaleLinear,
  scaleTime,
  axisBottom,
  axisLeft,
  csv,
  timeParse,
  max,
} from 'd3'
import useResizeObserver from '../useResizeObserver'
import '../../index.scss'

const dateParse = timeParse('%B %Y')
const dateParseShort = timeParse('%b %Y')

const isBrowser = typeof window !== 'undefined'

let isMobile = true;
if (isBrowser) {
  isMobile =  window.innerWidth > 771;
}

// charts margins
const margin = {
  top: 50,
  right: isMobile ? 80 : 70,
  bottom: isMobile ? 100 : 50,
  left: isMobile ? 50 : 40,
}

const labelPadding = 6

const colors = {
  'nyc-ride-share-final': '#655DC6',
  'chicago-ride-share-final': '#18988B',
}

const legends = {
  'nyc-ride-share-final': 'New York City Median Miles',
  'chicago-ride-share-final': 'Chicago Fares Median Miles',
}

const intialOpacity = {
  'nyc-ride-share-final': 1,
  'chicago-ride-share-final': 1,
}


const isNumeric = str => {
  if (typeof str != 'string') return false // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ) // ...and ensure strings of whitespace fail
}

// Line component
function Line(props) {
  const { data, className, color, width, x, y, svg, opacity, legend } = props
  const sortedData = data.sort((a, b) => a.date - b.date)
  const first = sortedData[0]
  const last = sortedData[sortedData.length - 1]
  const itemBeforeLast = sortedData[sortedData.length / 2]
  const offsetLabelKeyX = className === 'nyc-ride-share-final' ? 20 : -15
  const offsetLabelKeyY = className === 'nyc-ride-share-final' ? 50 : -80

  // Create the line generator.
  let lineShape = line()
    .x(d => x(d.date))
    .y(d => y(d.value))

  svg
    .select(`g.scrolly__chart__label.scrolly__chart__label--min.${className}`)
    .attr(
      'transform',
      `translate(${x(first.date) - 60}, ${y(first.value) + offsetLabelKeyX})`
    )
    .select('text')
    .attr('dy', '15px')
    .text(`${first.value} miles`)

  svg
    .select(`g.scrolly__chart__label.scrolly__chart__label--max.${className}`)
    .attr('transform', `translate(${x(last.date) + 10},${y(last.value)})`)
    .select('text')
    .attr('dy', '-10px')
    .text(`${first.value} miles`)

  svg
    .select(`g.scrolly__chart__label.scrolly__chart__label--key.${className}`)
    .attr(
      'transform',
      `translate(${x(itemBeforeLast.date)}, ${
        y(itemBeforeLast.value) + offsetLabelKeyY
      })`
    )
    .select('text')
    .text(d => legend)

  svg
    .selectAll(`g.scrolly__chart__label.${className} rect`)
    .datum(function () {
      return this.nextSibling.getBBox()
    })
    .attr('x', d => d.x - labelPadding)
    .attr('y', d => d.y - labelPadding)
    .attr('width', d => d.width + 2 * labelPadding)
    .attr('height', d => d.height + 2 * labelPadding)
    .attr('rx', 2)

  // Draw the line.
  svg
    .select(`path.scrolly__chart__line.${className}`)
    .attr('d', lineShape(sortedData))

  return (
    <g className={`${className}`} opacity={opacity}>
      <path
        className={`scrolly__chart__line ${className}`}
        strokeWidth={width}
        stroke={color}
        fill='none'
      />
      <g
        className={`scrolly__chart__label scrolly__chart__label--min ${className}`}
      >
        <rect></rect>
        <text fill={color}></text>
      </g>
      <g
        className={`scrolly__chart__label scrolly__chart__label--max ${className}`}
      >
        <rect></rect>
        <text fill={color}></text>
      </g>
      <g
        className={`scrolly__chart__label scrolly__chart__label--key ${className}`}
      >
        <rect fill={color}></rect>
        <text></text>
      </g>
    </g>
  )
}

export default function DefaultChart(props) {
  const { data, title, description } = props

  const [importedData, setData] = useState(null)
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const svgRef = useRef(null)
  const svgContainerRef = useRef(null)
  const { width } = useResizeObserver(svgContainerRef)
  // Set the svg height to half its width, but don't let it go lower than 600.
  const height = Math.max(width / 2, 600)

  const xRef = useRef(
    scaleTime()
      .range([margin.left, width - margin.right])
      .nice()
  )
  // Create the y-scale.
  const yRef = useRef(
    scaleLinear()
      .range([height - margin.bottom, margin.top])
      .nice()
  )
  const drawAxes = data => {
    const arr = data.flat()
    const svg = select(svgRef.current)
    const x = xRef.current
    const y = yRef.current

    const sortedData = arr.sort((a, b) => a.date - b.date)

    x.range([margin.left, width - margin.right])
    y.range([height - margin.bottom, margin.top])

    x.domain([dateParse('October 2018'), max(sortedData, d => d.date)])
    y.domain([0, 4])

    svg
      .select('g.scrolly__chart__axis--x')
      .attr('transform', d => `translate(0, ${height - margin.bottom})`)
      .call(axisBottom(x).ticks(10).tickSizeOuter(0))
      .selectAll('text')
      .attr('y', 0)
      .attr('x', 9)
      .attr('dy', '.35em')
      .attr('transform', 'rotate(90)')
      .style('text-anchor', 'start')
      .filter(function () {
        return isNumeric(select(this).text())
      })
      .attr('font-weight', 'bold')

    svg
      .select('g.scrolly__chart__axis--y')
      .attr('transform', d => `translate(${margin.left})`, 0)
      .call(axisLeft(y).ticks(height / 50))
      .call(g => g.select('.domain').remove())
      .call(g =>
        g
          .selectAll('.tick line')
          .clone()
          .attr('stroke-opacity', 0.1)
          .attr('x2', width - margin.right - margin.left)
      )
  }

  useEffect(() => {
    const fetchData = data => {
      let promises = []
      if (Array.isArray(data)) {
        promises = data.map(dataset =>
          csv(`https://www.datocms-assets.com/${dataset.path}`, d => {
            if (isNaN(parseFloat(d['% change in monthly mean fare']))) return
            return {
              name: dataset.basename,
              value: parseFloat(d['Median Miles Per Trip']),
              date: dateParse(d.date) || dateParseShort(d.date),
            }
          })
        )
      }

      Promise.all(promises).then(data => {
        setData(data)
        setIsDataLoaded(true)
      })
    }
    fetchData(data)
  }, [])

  useEffect(() => {
    select(svgRef.current)
      .attr('viewBox', [0, 0, width, height])
      .attr('width', width)
      .attr('height', height)
  }, [width, height])

  useEffect(() => {
    if (importedData) {
      drawAxes(importedData)
    }
  }, [importedData])

  return (
    <div ref={svgContainerRef} className="scrolly__wrapper--chart">
      {title && <h4 className="scrolly__chart__title">{title}</h4>}
      <svg ref={svgRef} className="scrolly__chart">
        <g className="scrolly__chart__axis scrolly__chart__axis--x" />
        <g className="scrolly__chart__axis scrolly__chart__axis--y" />
        {importedData ? (
          importedData.map((d, i) => (
            <Line
              className={d[0].name}
              key={i}
              data={d}
              color={colors[d[0].name]}
              width={"2px"}
              y={yRef.current}
              opacity={intialOpacity[d[0].name]}
              x={xRef.current}
              svg={select(svgRef.current)}
              legend={legends[d[0].name]}
            ></Line>
          ))
        ) : (
          <p>Data is loading or missing</p>
        )}
      </svg>
      {description && (
        <p className="scrolly__chart__description">{description}</p>
      )}
    </div>
  )
}