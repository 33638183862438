import React from "react"
import EAFormEmbed from '../EAFormEmbed'
import "./index.scss"

export default function InlineEveryactionForm({formData}) {

  return (
    <div className="inline-everyaction-form">
      <link
        rel="preload"
        href="https://d3rse9xjbp8270.cloudfront.net/at.js"
        as="script"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="https://d3rse9xjbp8270.cloudfront.net/at.min.css"
        as="style"
      />
      <script
        type="text/javascript"
        src="https://d3rse9xjbp8270.cloudfront.net/at.js"
        crossOrigin="anonymous"
      ></script>
      <iframe src={`https://secure.everyaction.com/${formData.eaFormId}`} height={`${formData.formHeight}px`}></iframe>
    </div>
  )
}
