
import React from 'react'
import InlineColumn from '../../InlineColumn'
import '../index.scss'

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~
*
* Steps for Scrolly component
*
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

export default function Step({content, index}) {
    return (
      <div className="scrolly__step" data-step={index}>
        <InlineColumn columnData={content[0]} columnClasses={"scrolly__step__content"}/>
      </div>
    )
  }