import React from "react"
import "./index.scss"

function getYoutubeVideoIdFromUrl(url) {
  var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  var match = url.match(regExp);
  if (match && match[2].length == 11) {
    return match[2];
  } else {
    return null
  }
}

function youtubeEmbedCode(videoUrl) {
  var videoId = getYoutubeVideoIdFromUrl(videoUrl)
  return (
    <iframe 
      src={`https://www.youtube.com/embed/${videoId}`} 
      title="YouTube video player" 
      frameBorder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
      allowFullScreen={true}>
    </iframe>
  )
}

function embedCode(videoData) {
  switch (videoData.videoSource) {
    case 'youtube':
      return youtubeEmbedCode(videoData.videoId)
  }
}

function styleClasses(videoData) {
  return 'inline-video-embed--'.concat(videoData.aspectRatio);
}

export default function InlineVideoEmbed({videoData}) {
  return (
    <div className={`inline-video-embed" ${styleClasses(videoData)}`}>
      {embedCode(videoData)}
    </div>
  )
}
