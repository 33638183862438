import React from 'react'

import ChartUberLyft1 from './charts/ChartUberLyft1';
import ChartUberLyft2 from './charts/ChartUberLyft2';
import ChartUberLyft3 from './charts/ChartUberLyft3';
import ChartUberLyft4 from './charts/ChartUberLyft4';
import Chart from './charts/Chart';
import '../index.scss'


/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~
*
* In this file, you can set the sticky element you want to interact with within
* scrollyWrapper 
* You can add a custom component here matching 'component_name' set in DatoCMS
*
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

export default function Element (props) {
    const { component_name, progress, data, title, description } = props;
    switch (component_name) {
        case 'ChartUberLyft1':
            return (<ChartUberLyft1 {...progress} data={data} title={title} description={description}></ChartUberLyft1>)
        case 'ChartUberLyft2':
            return (<ChartUberLyft2 {...progress} data={data} title={title} description={description}></ChartUberLyft2>)
        case 'ChartUberLyft3':
            return (<ChartUberLyft3 {...progress} data={data} title={title} description={description}></ChartUberLyft3>)
        case 'ChartUberLyft4':
            return (<ChartUberLyft4 {...progress} data={data} title={title} description={description}></ChartUberLyft4>)
        default:
            return (<Chart {...progress} data={data} title={title} description={description}></Chart>)
    }
} 

