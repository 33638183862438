import React from "react"
import { Link } from "gatsby"
import PageSection from "../../../components/PageSection"
import UJCity from "./uj-city-2.inline.svg";
import "./index.scss"

export default function UJHero({resourceType, title}) {

  return (
    <PageSection sectionClasses="uj-hero">

      <nav className="resource-hero__nav">
        <Link to="/resources" className="resource-hero__breadcrumb">
          ‹ Resources
        </Link>
      </nav>

      <div className="resource-hero__text">

        <p className="resource-hero__label">{resourceType.replaceAll("-", " ")}</p>

        <h1 className="resource-hero__title">{ title}</h1>
      
      </div>

      <UJCity className="uj-hero__background" />

    </PageSection>
  )
}
