import React from "react"
import InternalLink from '../InternalLink'
import InlineImage from '../InlineImage'
import "./index.scss"

function CardWrapper({cardData, classes, children}) {
  switch (cardData.linkType) {
    case "internal":
      return (
        <InternalLink
          record={cardData.internalLink}
          classes={classes}
          openInNewTab={true}
        >{children}</InternalLink>
      )
    case "external":
      return (
        <a href={cardData.externalLink} target="_blank" rel="noopener noreferrer" className={classes}>{children}</a>
      )
    case "file":
      return (
        <a href={cardData.fileLink.url} target="_blank" rel="noopener noreferrer" className={classes}>{children}</a>
      )
  }
}

function styleClasses(cardData) {
  return 'inline-link-card--theme-'.concat(cardData.backgroundColor);
}

export default function InlineLinkCard({cardData}) {
  return (
    <CardWrapper
      cardData={cardData}
      classes={`inline-link-card ${styleClasses(cardData)}`}
    >

      { cardData.cardImage && (
        <InlineImage imageData={cardData.cardImage} classes="inline-link-card__image" />
      )}

      <div className="inline-link-card__text">

        { cardData.cardHeading && (
          <h3 className="inline-link-card__heading">{cardData.cardHeading}</h3>
        )}

        { cardData.cardDescription && (
          <p className="inline-link-card__description">{cardData.cardDescription}</p>
        )}

        { cardData.cardMoreText && (
          <p className="inline-link-card__more-text">{cardData.cardMoreText}</p>
        )}

      </div>

    </CardWrapper>
  )
}
