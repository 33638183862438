import React from "react"
import "./index.scss"

export default function PageNavMenu({chaptersInfo, activeChapter}) {

  const chaptersLinks = chaptersInfo?.map(chapter => {
    return (
      <li key={chapter.slug} className={`page-chapters__nav-item page-chapters__nav-item--${chapter.level} ${activeChapter === chapter.slug ? 'page-chapters__nav-item--active' : ''}`}>
        <a href={`#${chapter.slug}`}>{chapter.label}</a>
      </li>
    )
  })

  return (
    <div className="page-chapters__nav-column">
      <nav className="page-chapters__nav-menu-wrapper">
        <div className="page-chapters__nav-menu">

          <h3 className="page-chapters__nav-title">Contents</h3>

          <ul>
            {chaptersLinks}
          </ul>
        </div>
      </nav>
    </div>
  )
}


// className={activeSection === 'section2' ? 'active' : ''}