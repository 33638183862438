import React, { useState, useEffect, useRef } from "react"
import PageNavChapter from "../PageNavChapter"
import PageNavMenu from "../PageNavMenu";
import "./index.scss"
const _ = require('lodash');

export default function PageChaptersWithNav({ chaptersData }) {


  // set up intersection observer for nav menu highlighting
  const [activeChapter, setActiveChapter] = useState(null);
  const chapterObserver = useRef(null);

  useEffect(() => {

    // options for interaction observer
    let observerOptions = {
      threshold: 0,
      rootMargin: "-39.9% 0% -59.9% 0%"
    }

    //create observer
    chapterObserver.current = new IntersectionObserver((entries) => {

      // reset for now - decided against
      // setActiveChapter(null);

      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // console.log(entry.target)
          setActiveChapter(entry.target.id)
        }
      });

    }, observerOptions);

    // get array of chapters to observe
    const chapters = document.querySelectorAll('.page-chapters__chapter');

    chapters.forEach((chapter) => {
      chapterObserver.current.observe(chapter);
    });

  }, []);

  const chapterLinkInfo = []

  const pageChapters = chaptersData?.map(chapterData => {
    switch (chapterData.__typename) {
      case "DatoCmsPageNavChapter":

        // calculate info for nav menu
        chapterLinkInfo.push({
          "label": chapterData.chapterLabel,
          "slug": _.kebabCase(chapterData.chapterLabel),
          "level": chapterData.chapterType
        })

        return (<PageNavChapter key={chapterData.id} chapterInfo={chapterData} />)
    }
  })

  return (
    <div className="page-chapters">
      <PageNavMenu chaptersInfo={chapterLinkInfo} activeChapter={activeChapter} />
      <div className="page-chapters__chapters-wrapper">
        {pageChapters}
      </div>
    </div>
  )
}
