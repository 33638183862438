import React from "react"
import PageSectionWithLayouts from "../PageSectionWithLayouts"
const _ = require('lodash');


export default function PageNavChapter({ chapterInfo }) {

  const chapterSections = chapterInfo.chapterContent?.map(pageSectionData => {
    switch (pageSectionData.__typename) {
      case "DatoCmsPageSection":
        return (<PageSectionWithLayouts key={pageSectionData.id} sectionData={pageSectionData} />)
    }
  })

  return (
    <div id={_.kebabCase(chapterInfo.chapterLabel)} className="page-chapters__chapter">
      {chapterSections}
    </div>
  )
}