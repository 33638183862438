import React from "react"
import InternalLink from '../InternalLink'
import InlineImage from '../InlineImage'
import { StructuredText } from 'react-datocms'
import PageSection from '../PageSection'
import "./index.scss"


function styleClasses(barData) {
  
  var styleClasses = []
  styleClasses.push('download-bar--theme-'.concat(barData.colorTheme))

  if (barData.overlapWithPreviousComponent) {
    styleClasses.push('download-bar--push-up')
  }

  return styleClasses.join(' ')
}


export default function DownloadBar({barData}) {

  const downloadLinks = barData.downloads.map(download => {
    return (
      <li className="download-bar__download-item" key={download.id}>
        <a href={download.pdf.url} className="download-bar__download-button btn btn-secondary" target="_blank" rel="noopener noreferrer">{download.buttonText}</a>
      </li>
    )
  })

  return (
    <PageSection sectionClasses="download-bar" colorTheme={barData.backgroundColor}>
      <div className={`download-bar__bar ${styleClasses(barData)}`}>
        { barData.coverPhoto && (
          <InlineImage imageData={barData.coverPhoto} classes="download-bar__cover-image" />
        )}

        <div className="download-bar__main">

          <div className="download-bar__text standard-content">
            { barData.introText && (
              <StructuredText 
                data={barData.introText}
                renderLinkToRecord={({ record, children }) => {
                  return (<InternalLink
                    record={record}
                    classes=""
                    children={children} 
                  />)
                }}
              />
            )}
          </div>

          <ul className="download-bar__downloads">
            {downloadLinks}
          </ul>

        </div>
      </div>
    </PageSection>
  )
}
