import React, { useRef, useEffect, useState } from "react"
import {
  select,
  line,
  scaleLinear,
  scaleTime,
  axisBottom,
  axisLeft,
  csv,
  timeParse,
  format,
  max,
} from 'd3'
import useResizeObserver from '../useResizeObserver'
import '../../index.scss'

const dateParse = timeParse('%B %Y')
const dateParseShort = timeParse('%b %Y')
const percFormat = format('.0%')

const isBrowser = typeof window !== 'undefined'
let isMobile = true;

if (isBrowser) {
  isMobile =  window.innerWidth > 771;
}

// charts margins
const margin = {
  top: 10,
  right: isMobile ? 80 : 30,
  bottom: isMobile ? 330 : 330,
  left: isMobile ? 50 : 30,
}

const labelPadding = 6

const markerColor = '#FD495C'
const colors = {
  'nyc-ride-share-final-new': '#655DC6',
  'chicago-ride-share-final-new': '#18988B',
}

const legends = {
  'nyc-ride-share-final-new': 'New York City Fares',
  'chicago-ride-share-final-new': 'Chicago Fares',
}

const intialOpacity = {
  'nyc-ride-share-final-new': 1,
  'chicago-ride-share-final-new': 1,
  'line-event-1': 1,
  'line-event-2': 0,
}

const chartConfig = {
  0: [
    {
      el: '.chicago-ride-share-final-new',
      opacity: 1,
    },
    {
      el: '.nyc-ride-share-final-new',
      opacity: 1,
    },
    {
      el: '.line-event-1',
      opacity: 1,
    },
    {
      el: '.line-event-2',
      opacity: 0,
    },
    {
      el: '.line-event-3',
      opacity: 0,
    },
  ],
  1: [
    {
      el: '.chicago-ride-share-final-new',
      opacity: 1,
    },
    {
      el: '.nyc-ride-share-final-new',
      opacity: 1,
    },
    {
      el: '.line-event-1',
      opacity: 1,
    },
    {
      el: '.line-event-2',
      opacity: 1,
    },
    {
      el: '.line-event-3',
      opacity: 0,
    },
  ],
  2: [
    {
      el: '.chicago-ride-share-final-new',
      opacity: 1,
    },
    {
      el: '.nyc-ride-share-final-new',
      opacity: 1,
    },
    {
      el: '.line-event-1',
      opacity: 1,
    },
    {
      el: '.line-event-2',
      opacity: 1,
    },
    {
      el: '.line-event-3',
      opacity: 1,
    },
  ],
}

const timeseriesMarkers = [
  {
    date: 'May 2019',
    event: 'Uber goes public on the stock market',
    pos: 16.52,
    url: '',
    id: 'event-1',
    anchor: 'end',
    mul: isMobile ? 1 : 0.7,
  },
  {
    date: 'November 2019',
    event: 'CEO predicts profitability by 2021',
    pos: 17.03,
    url: 'https://www.cnbc.com/2019/11/04/uber-ceo-dara-khosrowshahi-predicts-profitability-by-2021.html',
    id: 'event-1',
    anchor: 'end',
    mul: isMobile ? 2 : 2,
  },
  {
    date: 'February 2020',
    event: 'CEO tells investors “the era of growth at all costs is over”',
    pos: 22.5,
    url: 'https://www.sec.gov/Archives/edgar/data/1543151/000154315120000005/uberq419earningspressrelea.htm',
    id: 'event-1',
    anchor: 'end',
    mul: isMobile ? 3 : 3.3,
  },
  {
    date: 'March 2020',
    event: 'Pandemic hits',
    url: '',
    pos: 22.41,
    id: 'event-2',
    anchor: isMobile ? 'end' : 'middle',
    mul: isMobile ? 4 : 4.7,
  },
  {
    date: 'December 2020',
    event: 'CEO reiterates prediction Uber will be profitable in 2021',
    url: 'https://www.forbes.com/sites/sergeiklebnikov/2020/07/07/uber-says-it-will-become-profitable-next-year-boosted-by-265-billion-postmates-acquisition/?sh=62968d525f12',
    pos: 22.41,
    id: 'event-2',
    anchor: isMobile ? 'end' : 'start',
    mul: isMobile ? 4.5 : 5,
  },
  {
    date: 'February 2021',
    event:
      'Uber misses revenue expectations and reports a $6.7 billion loss in 2020',
    url: 'https://www.cnbc.com/2021/02/10/uber-earnings-q4-2020-.html',
    pos: 22.41,
    id: 'event-2',
    anchor: isMobile ? 'middle' : 'start',
    mul: isMobile ? 5.5 : 3.7,
  },
  {
    date: 'August 2021',
    event: 'Uber reports widening losses in Q2 2021',
    pos: 22.41,
    url: 'https://www.reuters.com/business/autos-transportation/uber-losses-widen-driver-incentives-rise-along-with-trips-2021-08-04/',
    id: 'event-2',
    anchor: 'start',
    mul: isMobile ? 4 : 2.3,
  },
  {
    date: 'August 2022',
    event: 'Uber is cash flow positive for the first time',
    url: 'https://www.ft.com/content/a454447f-c0b9-44fc-a24a-2781f1b7717e',
    pos: 22.41,
    id: 'event-3',
    anchor: 'middle',
    mul: isMobile ? 2 : 0.7,
  },
]

const isNumeric = str => {
  if (typeof str != 'string') return false // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ) // ...and ensure strings of whitespace fail
}

// Line component
function Line(props) {
  const { data, className, color, width, x, y, svg, opacity, legend } = props

  const sortedData = data.sort((a, b) => a.date - b.date)
  const first = sortedData[0]
  const last = sortedData[sortedData.length - 1]
  const itemBeforeLast = sortedData[10]
  const offsetLabelKeyX = className === 'nyc-ride-share-final-new' ? 20 : 40
  const offsetLabelKeyY = className === 'nyc-ride-share-final-new' ? -50 : -90

  // Create the line generator.
  let lineShape = line()
    .x(d => x(d.date))
    .y(d => y(d.value))

  svg
    .select(`g.scrolly__chart__label.scrolly__chart__label--min.${className}`)
    .attr(
      'transform',
      `translate(${x(first.date)},${y(first.value) - offsetLabelKeyX})`
    )
    .select('text')
    .attr('dy', '15px')
    .text(percFormat(first.value))

  svg
    .select(`g.scrolly__chart__label.scrolly__chart__label--max.${className}`)
    .attr('transform', `translate(${x(last.date)},${y(last.value)})`)
    .select('text')
    .attr('dy', '-10px')
    .text(percFormat(last.value))

  svg
    .select(`g.scrolly__chart__label.scrolly__chart__label--key.${className}`)
    .attr(
      'transform',
      `translate(${x(itemBeforeLast.date)},${
        y(itemBeforeLast.value) + offsetLabelKeyY
      })`
    )
    .select('text')
    .attr('font-size', isMobile ? '14px' : '12px')
    .attr('text-anchor', isMobile > 771 ? 'start' : 'middle')
    .text(d => legend)

  svg
    .selectAll(`g.scrolly__chart__label.${className} rect`)
    .datum(function () {
      return this.nextSibling.getBBox()
    })
    .attr('x', d => d.x - labelPadding)
    .attr('y', d => d.y - labelPadding)
    .attr('width', d => d.width + 2 * labelPadding)
    .attr('height', d => d.height + 2 * labelPadding)
    .attr('rx', 2)

  // Draw the line.
  svg.select(`path.scrolly__chart__line.${className}`).attr('d', lineShape(sortedData))

  return (
    <g className={`${className}`} opacity={opacity}>
      <path
        className={`scrolly__chart__line ${className}`}
        strokeWidth={width}
        stroke={color}
        fill='none'
      />
      <g
        className={`scrolly__chart__label scrolly__chart__label--min ${className}`}
      >
        <rect></rect>
        <text fill={color}></text>
      </g>
      <g
        className={`scrolly__chart__label scrolly__chart__label--max ${className}`}
      >
        <rect></rect>
        <text fill={color}></text>
      </g>
      <g
        className={`scrolly__chart__label scrolly__chart__label--key ${className}`}
      >
        <rect fill={color}></rect>
        <text></text>
      </g>
    </g>
  )
}

export default function DefaultChart(props) {
  const { currentStep, currentStepPct, data, title, description } = props

  const [importedData, setData] = useState(null)
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const svgRef = useRef(null)
  const svgContainerRef = useRef(null)
  const { width } = useResizeObserver(svgContainerRef)
  // Set the svg height to half its width, but don't let it go lower than 320.
  const height = Math.max(width / 2, 600)


  const xRef = useRef(
    scaleTime()
      .range([margin.left, width - margin.right])
      .nice()
  )
  // Create the y-scale.
  const yRef = useRef(
    scaleLinear()
      .range([height - margin.bottom, margin.top])
      .nice()
  )

  const drawAxes = data => {
    const arr = data.flat()
    const svg = select(svgRef.current)
    const x = xRef.current
    const y = yRef.current

    const sortedData = arr.sort((a, b) => a.date - b.date)

    x.range([margin.left, width - margin.right])
    y.range([height - margin.bottom, margin.top])
    x.domain([dateParse('October 2018'), max(sortedData, d => d.date)])
    y.domain([0, max(sortedData, d => d.value)])

    svg
      .select('g.scrolly__chart__axis--x')
      .attr('transform', d => `translate(0, ${height - margin.bottom})`)
      .call(axisBottom(x).ticks(10).tickSizeOuter(0))
      .selectAll('text')
      .attr('y', 0)
      .attr('x', 9)
      .attr('dy', '.35em')
      .attr('transform', 'rotate(90)')
      .style('text-anchor', 'start')
      .filter(function () {
        return isNumeric(select(this).text())
      })
      .attr('font-weight', 'bold')

    svg
      .select('g.scrolly__chart__axis--y')
      .attr('transform', d => `translate(${margin.left})`, 0)
      .call(
        axisLeft(y)
          .ticks(height / 50)
          .tickFormat(d => percFormat(d))
      )
      .call(g => g.select('.domain').remove())
      .call(g =>
        g
          .selectAll('.tick line')
          .clone()
          .attr('stroke-opacity', 0.1)
          .attr('x2', width - margin.right - margin.left)
      )
  }

  const addMarkers = (markers, data) => {
    markers.forEach((marker, i) => {
      const date = dateParse(marker.date)
      const x = xRef.current
      const y = yRef.current
      const svg = select(svgRef.current)
      const splitSentence = marker.event.split(' ')
      const splitMul = isMobile ? 2 : 3

      let g = svg
        .append('g')
        .attr('class', `marker line-${marker.id} line-${i}`)
        .attr('opacity', 0)

      g.append('circle')
        .attr('cx', x(date))
        .attr('cy', y(-0))
        .attr('r', 5)
        .attr('stroke', 'white')
        .attr('fill', markerColor)

      g.append('line')
        .attr('x1', x(date))
        .attr('y1', y(0))
        .attr('x2', x(date))
        .attr('y2', y(-0) + 50 * marker.mul)
        .style('stroke-width', 1)
        .style('stroke-dasharray', 4)
        .style('stroke', markerColor)
        .style('fill', 'none')

      let a = g.append('a').attr('href', marker.url).style('font-weight', 300)

      if (splitSentence.length > 2) {
        const chunkSize = Math.round(splitSentence.length / splitMul)

        for (let j = 0; j < splitSentence.length; j += chunkSize) {
          const chunk = splitSentence.slice(j, j + chunkSize)
          const lineHeigth = 15 / chunkSize
          const lineHeigthMul = j + 1
          a.append('text')
            .attr('x', x(date))
            .attr('y', y(-0.1) + lineHeigth * lineHeigthMul + 50 * marker.mul)
            .attr('class', `line-${marker.id}`)
            .attr('fill', markerColor)
            .attr('text-anchor', marker.anchor)
            .text(chunk.join(' '))
            .attr('font-size', '12px')
        }
      } else {
        a.append('text')
          .attr('x', x(date))
          .attr('y', y(-0.1) + 50 * marker.mul)
          .attr('class', `line-${marker.id}`)
          .attr('fill', markerColor)
          .attr('text-anchor', marker.anchor)
          .text(marker.event)
          .attr('font-size', '12px')
      }
    })
  }

  const updateChart = step => {
    const changes = chartConfig[step]
    changes.forEach(change => {
      let el = change.el
      let opacity = change.opacity
      select(svgRef.current).selectAll(`${el}`).attr('opacity', opacity)
    })
  }

  useEffect(() => {
    const fetchData = data => {
      let promises = []
      if (Array.isArray(data)) {
        promises = data.map(dataset =>
          csv(`https://www.datocms-assets.com/${dataset.path}`, d => {
            // if (isNaN(parseFloat(d['% change in monthly mean fare']))) return
            return {
              name: dataset.basename,
              value: parseFloat(d.percChangeSinceFeb),
              date: dateParse(d.date) || dateParseShort(d.date),
            }
          })
        )
      }

      Promise.all(promises).then(data => {
        setData(data)
        setIsDataLoaded(true)
      })
    }

    fetchData(data)
  }, [])

  useEffect(() => {
    select(svgRef.current)
      .attr('viewBox', [0, 0, width, height])
      .attr('width', width)
      .attr('height', height)
  }, [width, height])

  useEffect(() => {
    if (importedData) {
      drawAxes(importedData)
      addMarkers(timeseriesMarkers)
    }
  }, [importedData])

  useEffect(() => {
    if (currentStepPct > 0.5) {
      updateChart(currentStep)
    }
  }, [currentStepPct])

  return (
    <div ref={svgContainerRef} className="scrolly__wrapper--chart">
      {title && <h4 className="scrolly__chart__title">{title}</h4>}
      <svg ref={svgRef} className="scrolly__chart">
        <g className="scrolly__chart__axis scrolly__chart__axis--x" />
        <g className="scrolly__chart__axis scrolly__chart__axis--y" />
        {importedData ? (
          importedData.map((d, i) => (
            <Line
              className={d[0].name}
              key={i}
              data={d}
              color={colors[d[0].name]}
              width={"2px"}
              y={yRef.current}
              opacity={intialOpacity[d[0].name]}
              x={xRef.current}
              svg={select(svgRef.current)}
              legend={legends[d[0].name]}
            ></Line>
          ))
        ) : (
          <p>Data is loading or missing</p>
        )}
      </svg>
      {description && (
        <p className="scrolly__chart__description">{description}</p>
      )}
    </div>
  )
}