import { useState, useEffect } from 'react'

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~
*
* This function handles viewport resizing when using IntersectionObserver
*
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

export default function useResizeObserver(element) {
  const [size, setSize] = useState({
    width: 100,
    height: 100,
  })

  useEffect(() => {
    const node = element.current
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { contentRect } = entry
        const { width, height } = contentRect
        setSize({
          width,
          height,
        })
      }
    })

    if (node) resizeObserver.observe(node)

    return () => {
      if (node) resizeObserver.unobserve(node)
    }
  }, [element])

  return size
}
