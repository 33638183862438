import React, { useState, useEffect, useRef } from 'react'
import { range } from 'lodash'
import Step from './Steps'
import '../index.scss'



/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~
*
* Wrapper for intersectionObserver
* Here we also calculate overallPct, currentStep, currentStepPct
*
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */


// This function finds the intersection observer entry with the highest
// ratio. For example, if we have the following entries:
// [{ step: 0, ratio: 0.9 }, { step: 1, ratio: 0.1 }, { step: 2, ratio: 0 } ]
// The highest ratio is { step: 2, ratio: 0 }.
// The overall ratio is then 2 (2 + 0).
// Finally, the overall pct is calculated as:
// Math.min(2 + 0, 3 - 1) / (3 - 1), or
// 2 / 2 = 1 (2 being the number of steps).
// The function returns this * 100.
const getScrollProgress = observerEntries => {
    const maxStepProgress =
      observerEntries
        .filter(entry => entry && entry.ratio > 0)
        .map(entry => entry.step + entry.ratio)
        .sort((a, b) => a - b)
        .slice(-1)[0] || 0
  
    const overallPct =
      Math.min(maxStepProgress, observerEntries.length - 1) /
      (observerEntries.length - 1)
  
    const currentStep =
      overallPct < 1 ? Math.trunc(maxStepProgress) : observerEntries.length - 2
  
    const currentStepPct =
      overallPct < 1 ? maxStepProgress - Math.trunc(maxStepProgress) : 1
  
    return { overallPct, currentStep, currentStepPct }
  }
  

export default function ScrollyWrapper (props) {
  const { onProgressHandler } = props

  let { steps } = props;
  const [topBuffer, setTop] = useState(0) 
  
  // Add a padding step. We need this to make sure the overallPct
  // doesn't go back down from 100% as we scroll down.
  // For more on this, see overallPct and comments in the render section.
  steps = steps.concat({ content: '' })

  const parentElementRef = useRef(null);
  const [observerEntries, setObserverEntries] = useState([
    ...Array(steps.length),
  ])

  useEffect(() => {
    const options = {
      threshold: range(0, 1, 0.0001).concat(1)
    }


  // This array holds intersection observer entries
  // and their corresponding intersection ratios.
  // We'll use that when calculating the overall percentage.
  // e.g. [{step: 0, ratio: 0.5}, {step: 1, ratio: 0}...]
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setObserverEntries(observerEntries =>
          observerEntries.map((value, index) =>
            index === +entry.target.dataset.step
              ? {
                step: +entry.target.dataset.step,
                ratio: entry.intersectionRatio,
              }
              : value
          )
        )

      })
    }, options)

    const targets = parentElementRef.current.querySelectorAll('section > div')
    
    targets.forEach(target => {
      observer.observe(target)
    })

    return () => {
      targets.forEach(target => {
        observer.unobserve(target)
      })
    }
  }, [])

  useEffect(() => {
    const scrollProgress = getScrollProgress(observerEntries)
    onProgressHandler(scrollProgress)

    // this is styling so that we don't have as much space above the steps
    setTop(-window.innerHeight / 2)
  }, [observerEntries, onProgressHandler])

  return (
    <>
      <div
        ref={parentElementRef}
        className="scrolly"
      >

        <div className="scrolly__wrapper--element">
          <div
            className="scrolly__element"
          >
            {props.children}
          </div>
        </div>
        <section style={{top: topBuffer}} className="scrolly__wrapper--step">
          {/* Add a div for each step
           * Observe we're skipping the last step, the empty one.
           */}
          {steps.slice(0, steps.length - 1).map((data, i) => {
            return (
              <Step
                key={i}
                stepAlignment={data.stepAlignment}
                index={i}
                content={data.content}
              />
            )
          })}
          <div data-step={steps.length - 1} style={{ height: 1 }} />
            {/* Add an extra final step. Note it has height 1 pixel.
            * So why do we need it?
            *
            * Say we have two steps:
            * [{ step: 0 }, { step: 1 }]
            *
            * And say we've scrolled to the end of step 1:
            * [{ step: 0, ratio: 0 }, { step: 1, ratio: 1 }]
            *
            * At this point, the overallPct is 100.
            * (consult overallPct's documentation for more on this).
            *
            * If we continue scrolling, as step 1 scrolls up and out of
            * visibility, we'll get something like this:
            * [{ step: 0, ratio: 0 }, { step: 1, ratio: 0.9 }]
            *
            * At this point, the overallPct is 95.
            * Which means whatever element is attached to the overPct would 
            * scroll back to 95 when we scroll past. We want it to stay 100%
            *
            * Hence this final step. If we add it, we'll get this instead:
            * [{ step: 0, ratio: 0 }, { step: 1, ratio: 0.9 },
            *  { step: 2, ratio: 0.1 }]
            *
            * But because of the way overallPct clamps its pct,
            * it will return Math.min(2 + 0.1, 2) / 2, or 1.
            * After we * 100, that's 100%.

            * In other words:
            * this last 1-pixel step, in combination with
            * overallPct, ensures that as we scroll down, we don't accidentally trigger the element attached to the overallPct to be triggered backwards. 
            * .
          */}

        </section>
      </div>
    </>
  )
}