import React, { useRef, useEffect, useState } from 'react'
import { select, csv } from 'd3'
import useResizeObserver from '../useResizeObserver'
import '../../index.scss'

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~
* This file provides a skeleton for rigging up a D3
  chart scrolly inside a React component.
*
* All D3 code goes in the `useEffect` hook, which runs
  every time the `data` prop changes, but only after the
  <svg> is drawn on the DOM.

* The second `useEffect` hook runs every time the overall percentage of scroll is updated.
*
* For more info on D3 in React, you can read this:
  https://medium.com/@jeffbutsch/using-d3-in-react-with-hooks-4a6c61f1d102
*
* You may want to limit the import statement below to only the
  specific D3 libraries you need.
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

export default function DefaultChart(props) {
  const { overallPct, currentStep, currentStepPct, data, title, description } =
    props

  let isMobile = true;
  if (isBrowser) {
    isMobile =  window.innerWidth > 771;
  }

  // charts margins
  const margin = {
    top: 10,
    right: isMobile ? 80 : 30,
    bottom: isMobile ? 330 : 330,
    left: isMobile ? 50 : 30,
  }

  const svgRef = useRef(null)
  const svgContainerRef = useRef(null)
  const [importedData, setData] = useState(null)
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const { width } = useResizeObserver(svgContainerRef)
  // Set the svg height to half its width, but don't let it go lower than 320.
  const height = Math.max(width / 2, 320)

  const updateChart = step => {
    // Here you can select an element and trigger an interaction based on the step number. This can be updated to get triggered on any of the three progression factors (overall percentage, step percentage, step number)
    if (step === 1) {
      // do something on step with index 1
    }
  }

  useEffect(() => {
    const fetchData = data => {
      // the data from DatoCMS comes in an array even if there is only one file
      let promises = []
      promises = data.map(dataset =>
        csv(`https://www.datocms-assets.com/${dataset.path}`, d => {
          return {
            //  you can make changes to the data here
          }
        })
      )
      Promise.all(promises).then(data => {
        setData(data)
        setIsDataLoaded(true)
      })
    }
    fetchData(data)
  }, [])

  useEffect(() => {
    if (importedData && svgRef.current) {
      const svg = select(svgRef.current)

      /*******************
       * D3 CODE GOES HERE *
       ********************* */

    }
  }, [data])

  useEffect(() => {
    // update width or height
    select(svgRef.current)
      .attr("viewBox", [0, 0, width, height])
      .attr("width", width)
      .attr("height", height)
  }, [width, height])

  useEffect(() => {
    // this gets triggered when the step is at 50% of the viewport
    if (currentStepPct > 0.5) {
      // Update the chart based on step or overallPct
      updateChart(currentStep)
    }
  }, [currentStepPct])

  return (
    <div ref={svgContainerRef} className="scrolly__wrapper--chart">
      {title && <h4 className="scrolly__chart__title">{title}</h4>}
      <svg ref={svgRef} className="scrolly__chart" />
      {description && (
        <p className="scrolly__chart__description">{description}</p>
      )}
    </div>
  )
}
