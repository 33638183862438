import React from "react"
import InlineImage from '../InlineImage'
import "./index.scss"

function themeClasses(colorTheme) {
  return 'chapter-cover--theme-'.concat(colorTheme);
}
function layoutClasses(layout) {
  return 'chapter-cover--layout-'.concat(layout);
}

export default function ChapterCover({chapterData}) {

  return (
    <div className={['page-section__content chapter-cover', themeClasses(chapterData.colorTheme), layoutClasses(chapterData.layout)].join(' ')}>

      <div className={`chapter-cover__text ${chapterData.image ? 'chapter-cover__text--has-image' : ''}`}>

        { chapterData.pretitle && (
          <p className="chapter-cover__pretitle">{chapterData.pretitle}</p>
        )}
        
        { chapterData.title && (
          <h2 className="chapter-cover__title">{chapterData.title}</h2>
        )}

        { chapterData.subtitle && (
          <p className="chapter-cover__subtitle">{chapterData.subtitle}</p>
        )}
      </div>

      { chapterData.image && (
        <InlineImage imageData={chapterData.image} classes="chapter-cover__image" />
      )}

    </div>
  )
}
