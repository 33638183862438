import React from "react"
import ResourceHero from "../ResourceHero"
import FeatureCTA from "../FeatureCTA"
import ReportSlider from "../ResourceComponents/ReportSlider"
import PageNavChapter from "../PageNavChapter"
import PageSectionWithLayouts from "../PageSectionWithLayouts"
import DownloadBar from "../DownloadBar";
import PageChaptersWithNav from "../PageChaptersWithNav"
import ScrollyElement from "../Scrolly/ScrollyElement"
import "./index.scss"

export default function ModularPageSections({ sectionsData, resourceType, pageTitle, pageTagline }) {
  const pageSections = sectionsData?.map(pageSectionData => {
    switch (pageSectionData.__typename) {
      case "DatoCmsPageSection":
        return (<PageSectionWithLayouts key={pageSectionData.id} sectionData={pageSectionData} />)
      case "DatoCmsFeatureCta":
        return (<FeatureCTA key={pageSectionData.id} component={pageSectionData} />)
      case "DatoCmsResourceHero":
        return (<ResourceHero key={pageSectionData.id} content={pageSectionData} resourceType={resourceType} pageTitle={pageTitle} pageTagline={pageTagline} />)
      case "DatoCmsPageNavChapter":
        return (<PageNavChapter key={pageSectionData.id} chapterInfo={pageSectionData} />)
      case "DatoCmsDownloadBar":
        return (<DownloadBar key={pageSectionData.id} barData={pageSectionData} />)
      case "DatoCmsSectionsWithNavWrapper":
        return(<PageChaptersWithNav key={pageSectionData.id} chaptersData={pageSectionData.components} />)
      case "DatoCmsReportSlider":
        return(<ReportSlider key={pageSectionData.id} component={pageSectionData} />)
      case "DatoCmsScrollyElement":
        return (<ScrollyElement key={pageSectionData.id} component={pageSectionData}/>)
      default:
        return null
    }
  })

  return (
    <div>
      {pageSections}
    </div>
  )
}
